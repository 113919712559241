import { AppConfig, BaseService, repo } from '@luxms/bi-core';
import ResourceLocatorService, { ResourcesOfDatasetsTreeService } from './ResourceLocatorService';
import axios from 'axios';

interface IResourceByNameModel {
  error: string;
  loading: boolean;
  resource: repo.ds.IRawResource;
  schema_name: string;
  content: string;
}

export default class ResourceByNameService extends BaseService<IResourceByNameModel> {
  public readonly MODEL: IResourceByNameModel;
  private _resourcesOfDatasetsTreeService: any = null;

  public constructor(private readonly alt_id: string) {
    super({
      error: null,
      loading: true,
      resource: null,
      schema_name: null,
      content: null,
    });

    this._resourcesOfDatasetsTreeService = new ResourcesOfDatasetsTreeService(this.alt_id);
    //
    this._resourcesOfDatasetsTreeService.subscribeUpdates(this._onResourceUpdated);


    this._onResourceUpdated();
  }

  protected _dispose() {
    this._resourcesOfDatasetsTreeService.unsubscribe(this._onResourceUpdated);
    this._resourcesOfDatasetsTreeService.release();
    this._resourcesOfDatasetsTreeService = null;

    super._dispose();
  }

  private _onResourceUpdated = async () => {
    const resourceTree = this._resourcesOfDatasetsTreeService.getModel();

    if (resourceTree.error) return this._updateWithError(resourceTree.error);
    if (resourceTree.loading) return this._updateWithLoading();

    let schema_name: string = null,
        resource: repo.ds.IRawResource = null;

    if (resourceTree.length) {
      schema_name = resourceTree[0].schema_name;
      resource = resourceTree[0].resource;
    }

    // Тут бы отменять загрузку файла если сюда пришли еще раз
    if (resource) {
      const url = AppConfig.fixRequestUrl(`/srv/resources/${schema_name}/${this.alt_id}`);
      const response = await axios.get(url);
      this._updateWithData({resource, schema_name, content: response.data});
    } else {
      this._updateWithData({resource: null, schema_name: null, content: null});
    }
  }
}
