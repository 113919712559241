/**
 * Сервис следит за одним файлом из ресурсов
 * Обновляется лишь тогда, когда запись в таблице resources о нем поменялась
 * Игнорирует любые другие изменения ресурсов
 *
 * В качестве schema_name можно передать null - тогда будет текущий датасет
 */

import { BaseService, IUrl, repo, srv, UrlState } from '@luxms/bi-core';
import isEqual from 'lodash/isEqual';
import createService from './createService';


interface IResourceLocator {
  readonly error: string;
  readonly loading: boolean;
  readonly schema_name: string;
  readonly resource: repo.ds.IRawResource;
}

export default class ResourceLocatorService extends BaseService<IResourceLocator> {
  public readonly MODEL: IResourceLocator;
  private _resourcesService: srv.ds.ResourcesService = null;
  private _schemaName: string = undefined;

  public constructor(schema_name: string, private readonly alt_id: string) {
    super({
      error: null,
      loading: true,
      schema_name,
      resource: null,
    });

    if (schema_name) {                                                                              // датасет явно указан
      this._schemaName = schema_name;
      this._resourcesService = srv.ds.ResourcesService.createInstance(schema_name);
      this._resourcesService.subscribeUpdatesAndNotify(this._onResourcesUpdated);
    } else {                                                                                        // Если нужен текущий датасет
      UrlState.subscribeAndNotify('segment segmentId', this._onUrlStateUpdated);
    }
  }

  protected _dispose() {
    UrlState.unsubscribe(this._onUrlStateUpdated);

    if (this._resourcesService) {
      this._resourcesService.unsubscribe(this._onResourcesUpdated);
      this._resourcesService.release();
      this._resourcesService = null;
    }

    super._dispose();
  }

  private _onUrlStateUpdated = (url: IUrl) => {
    const currentSchemaName = url.segment === 'ds' ? url.segmentId : null;
    if (this._schemaName !== currentSchemaName) {
      this._schemaName = currentSchemaName;

      if (this._resourcesService) {
        this._resourcesService.unsubscribe(this._onResourcesUpdated);
        this._resourcesService.release();
        this._resourcesService = null;
      }

      if (this._schemaName) {
        this._resourcesService = srv.ds.ResourcesService.createInstance(this._schemaName);
        this._resourcesService.subscribeUpdatesAndNotify(this._onResourcesUpdated);
      } else {                                                                                      // Датасет не выбран
        this._updateWithData({schema_name: null, resource: null});
      }
    }
  }

  private _onResourcesUpdated = (resources: typeof srv.ds.ResourcesService.MODEL) => {
    if (resources.error && resources.error !== 'Not authenticated') return this._updateWithError(resources?.error);     // TODO: вытащить константу NOT_AUTHENTICATED из core
    if (resources.loading) return this._updateWithLoading();                                                        // Игнорируем ошибку NOT_AUTHENTICATED - считаем что файлов в датасете нет
                                                                                                                    // По хорошему надо считать что датасет не выбран, если не аутентифицированы
    const resource = resources.find(r => r.alt_id === this.alt_id) ?? null;

    if (!this._model.error && !this._model.loading && isEqual(this._model.resource, resource) && this._schemaName === this._model.schema_name) {
      // Ничего не поменялось, скипаем
    } else {
      this._updateWithData({resource, schema_name: this._schemaName});
    }
  }
}


interface IResourcesOfDatasetsTreeItem {
  readonly schema_name: string;
  readonly resource: repo.ds.IRawResource;
}


export interface IResourcesOfDatasetsTree extends Array<IResourcesOfDatasetsTreeItem> {
  error: string;
  loading: boolean;
}

/**
 * Основываясь на текущем датасете, и иерархии датасетов (parent_guid) сервис загружает список ресурсов по имени
 *
 *  Сначала ищет ресурс в текущем датасете, в датасете, указанном по parent_guid, его родителю и так далее
 *  в конце ищет в ds_res
 */
export const ResourcesOfDatasetsTreeService = createService<IResourcesOfDatasetsTree>('ResourcesOfDatasetsTreeService', ({useService, useServiceItself, useServiceWithCustomSubscription}, alt_id) => {
  const url = useServiceWithCustomSubscription(UrlState, 'segment segmentId');
  const datasets = useService(srv.adm.DatasetsService);
  const isAuthenticated = datasets.error !== 'Not authenticated';
  if (datasets.error && isAuthenticated) return Object.assign([], {error: datasets.error, loading: false});
  if (datasets.loading) return Object.assign([], {error: null, loading: true});

  let schemaNames: string[] = [];

  let schema_name: string | null = url.segment === 'ds' ? url.segmentId : null;                     // текущая schema_name
  while (schema_name) {
    let ds = datasets.find(ds => ds.schema_name === schema_name);
    if (!ds) break;                                                                                 // если нашли датасет
    schemaNames.push(ds.schema_name);                                                               //  - то запоминаем его schema
    const parentDs = datasets.find(d => d.guid === ds.parent_guid);
    schema_name = parentDs?.schema_name;                                                            // если нашли родительский датасет - то дальше будем смотреть в него
  }

  // if (!isAuthenticated || datasets.find(d => d.schema_name === 'ds_res')) {                         // дополнительно добавим ds_res, в том числе, если незалогинены
  schemaNames.push('ds_res');
  // }

  let resourceServices: srv.ds.ResourcesService[] = schemaNames.map(schema_name => useServiceItself(srv.ds.ResourcesService, schema_name));

  const error = resourceServices.reduce((acc, rs) => acc || rs.getModel().error, null);
  const loading = resourceServices.reduce((acc, rs) => acc || rs.getModel().loading, null);
  if (error) return Object.assign([], {error, loading: false});
  if (loading) return Object.assign([], {error: null, loading: true});

  const items: IResourcesOfDatasetsTreeItem[] = resourceServices
      .map<IResourcesOfDatasetsTreeItem>(rs => ({resource: rs.getModel().find(resource => resource.alt_id === alt_id), schema_name: rs._schemaName}))      // Ой, тут я лезу в protected! Надо сделать публик-метод
      .filter(item => item.resource);

  return Object.assign(items, {error: null, loading: false});
});

