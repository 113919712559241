import { BaseService, AppConfig, createSingleton, extractErrorMessage } from '@luxms/bi-core';
import ResourceByNameService from '../services/ResourceByNameService';
import axios from 'axios';
import json5 from 'json5';


export interface IL10nVM {
  error: string;
  loading: boolean;
  name: string;
  current: any;
  locales: {[key: string]: any};
}

function mergeL10n(l10n: any, additionalL10n: any): any {
  const newL = (typeof additionalL10n === 'string') ? JSON.parse(additionalL10n) : additionalL10n;
  return {...l10n, ...newL};
}

/**
 * @class
 * @instance
 * @description Сервис подтягивает из ресурсов локазизацию,
 * Если в ресурсах ничего нет, берется локализация из Luxms
 */
export default class L10nVC extends BaseService<IL10nVM> {
  public static MODEL: IL10nVM;
  private _resourceService: ResourceByNameService;
  private _locales: {[key: string]: any} = {};
  private _name: string = '';

  protected constructor() {
    super({
      error: null,
      loading: true,
      name: '',
      current: {},
      locales: {},
    });

    this.setLocale(window.localStorage.getItem('locale') ?? AppConfig.getModel().language);
  }

  protected _dispose() {
    if (this._resourceService) {
      this._resourceService.unsubscribe(this._onServiceUpdated);
      this._resourceService.release();
      this._resourceService = null;
    }
    super._dispose();
  }

  public async setLocale(name: string) {
    const prevName = this._name || name;
    this._name = name;
    // грязный хек для css:after,before
    document.querySelector('html').classList.remove(prevName);
    document.querySelector('html').classList.add(name);

    if (!this._locales[name]) {                                                                     // сначала надо загрузить основной файл локали
      if (name === 'en' || name === 'ru') {
        try {
          const response = await axios.get(`assets/locales/${name}.json`);
          const data = (typeof response.data === 'string') ? json5.parse(response.data) : response.data;
          this._locales[name] = data;
        } catch (err) {
          console.error(err);
          this._locales[name] = {};
        }
      } else {                                                                                      // неизвестная локаль
        this._locales[name] = {};                                                                   // для нее ничего не понятно, но, может, она будет в ресурсах
      }
    }

    if (this._resourceService) {
      this._resourceService.unsubscribe(this._onServiceUpdated);
      this._resourceService.release();
      this._resourceService = null;
    }
    this._resourceService = new ResourceByNameService(`luxury_store/locales/${name}.json`);
    this._resourceService.subscribeUpdatesAndNotify(this._onServiceUpdated);
  }

  private _onServiceUpdated = () => {
    const resource = this._resourceService.getModel();

    if (resource.error) return this._updateWithError(extractErrorMessage(resource.error));
    if (resource.loading) return null;

    let currL10n: any = this._locales[this._name];
    if (resource.content) currL10n = mergeL10n(currL10n, resource.content);

    this._updateModel({
      error: null,
      loading: false,
      name: this._name,
      current: currL10n,
      locales: this._locales,
    });
    // обратная совместимость требует от нас сделать это
    (window as any).Luxms.lang = currL10n;
  }


  public setLocaleAndSave(name: string): Promise<any> {
    window.localStorage.setItem('locale', name);
    return this.setLocale(name);
  }

  public static getInstance: () => L10nVC = createSingleton<L10nVC>(() => new L10nVC(), '__l10nVC');

  public static setLocale(name: string): Promise<any> {
    return this.getInstance().setLocale(name);
  }

  public static setLocaleAndSave(name: string): Promise<any> {
    return this.getInstance().setLocaleAndSave(name);
  }
}
