import {
  AppConfig,
  AuthenticationService,
  BaseService,
  createSingleton,
  IBaseModel,
  IDisposable
} from '@luxms/bi-core';
import axios from 'axios';
import nextTick from 'next-tick';

type CanIModel = IBaseModel & Record<string, boolean>;

class CanIService extends BaseService<CanIModel> {
  public static MODEL: CanIModel;
  private _isCompatibleMode: boolean = false;

  private _pending: {[claim: string]: boolean} = {};
  private _pendingPromise: any = null;
  private _pendingResolve: any;

  private _queued: {[claim: string]: boolean} = {};
  private _queuedPromise: any = null;
  private _queueResolve: any;

  private _currentRequest: Promise<any> | null = null;

  public constructor() {
    super({loading: false, error: null});
  }

  public one(claim: string): Promise<boolean> {
    return this.ensure([claim])
        .then(model => model[claim])
        .catch(err => false);
  }

  public ensure(claims: string[]): Promise<CanIModel> {
    const queueds: string[] = claims.filter(claim => this._queued[claim]);
    const pendings: string[] = claims.filter(claim => this._pending[claim]);

    claims = claims.filter(claim => this._model[claim] === undefined && !this._pending[claim] && !this._queued[claim]);

    if (!claims.length) {                                                                           // все клеймы уже были у нас
      if (queueds.length) {                                                                         // но среди них есть стоящие в очереди
        return this._queuedPromise;
      } else if (pendings.length) {                                                                 // есть те, которые сейчас загружаются
        return this._pendingPromise;
      } else {                                                                                      // все готовы
        return Promise.resolve(this._model);
      }
    }

    // Надо какие-то загрузить
    claims.forEach(claim => this._queued[claim] = true);                                            // ставим их в очередь

    if (!this._queuedPromise) {                                                                     // если еще не было очереди, то создаем ее
      this._queuedPromise = new Promise<CanIModel>(resolve => { this._queueResolve = resolve; });   // и сохраняем функцию-резолвер
    }

    if (!this._currentRequest) {                                                                    // Ничего не запущено
      this._currentRequest = new Promise(resolve => resolve()).then(() => this._run());             // запускаем в следующем тике
    }

    return this._queuedPromise;
  }

  // очищает очередь загрузки и запускается еще раз, если
  private async _run(): Promise<CanIModel> {
    console.assert(Object.keys(this._queued).length !== 0);                                         // Очередь не пуста
    console.assert(Object.keys(this._pending).length === 0);                                        // никто не загружается сейчас
    console.assert(this._pendingPromise === null);

    const claims = Object.keys(this._queued);

    this._queued = {};                                                                              // очищаем очередь
    claims.forEach(claim => this._pending[claim] = true);                                           // и ее всю помечаем их как пендящиеся
    this._pendingPromise = this._queuedPromise;                                                     // выставляем теперь все в pending
    this._pendingResolve = this._queueResolve;
    this._queuedPromise = null;
    this._queueResolve = null;

    let newLoadedItems;

    try {
      if (this._isCompatibleMode) {
        newLoadedItems = await this._loadCompatible(claims);
      } else {
        newLoadedItems = await this._load(claims);
      }

    } catch (err) {
      console.error(err);
      // будем делать вид, что все в порядке, но нам все запрещено
      newLoadedItems = {};
      claims.forEach(claim => newLoadedItems[claim] = false);
    }

    const resolve = this._pendingResolve;                                                           // прихраниваем резолвер, чтоб вызвать его ПОСЛЕ updateModel - когда модель точно готова

    this._pending = {};                                                                             // все стали пропенженными
    this._pendingPromise = null;                                                                    // очищаем пендинговские переменные
    this._pendingResolve = null;                                                                    // делаем это ДО updateModel, потому что subscriber'ы могут дергать ensure

    this._updateModel(newLoadedItems);

    resolve(this._model);

    if (Object.keys(this._queued).length) {                                                         // за время загрузки могли появиться в очереди новые элементы - надо перезапустить цикл run
      this._currentRequest = new Promise(resolve => resolve()).then(() => this._run());
    } else {
      this._currentRequest = null;
    }

    return this._model;
  }


  private _load(claims: string[]): Promise<any> {
    // const source = axios.CancelToken.source();
    return axios({
      url: AppConfig.fixRequestUrl(`/api/can/i`),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      data: claims,
      // cancelToken: source.token,

    }).then((response) => {
      return this._updateModel(response.data);

    }).catch(err => {
      if (err.isAxiosError && err.response.status === 404) {
        this._isCompatibleMode = true;
        return this._loadCompatible(claims);
      } else {                                                          // непонятная ошибка, непонятно, что делать
        throw err;
      }
    });
  }

  private _loadCompatible(claims: string[]): Promise<any> {
    return new Promise(resolve => {
      let partial: { [claim: string]: boolean } = {};
      const isAdmin = AuthenticationService.getModel().access_level === 'admin';
      claims.forEach(claim => {
        if (claim === 'L koob.cubes') partial[claim] = true;                                          // читать глобальные кубы всякий горазд
        else if (claim === 'L koob.dimensions') partial[claim] = true;                                //  и дименшены
        else if (claim.match(/^\w+ rbac/)) partial[claim] = false;                                    // Ваще не умеем в RBAC
        else if (claim.match(/^\w+ ds_\w+\.data_sources/)) partial[claim] = false;                    // Не умеем в data_sources в датасетах
        else if (claim.match(/^\w+ ds_\w+\.cubes/)) partial[claim] = false;                           // Не умеем в кубы в датасетах
        else partial[claim] = isAdmin;
      });
      nextTick(() => {
        resolve(partial);
      });
    });
  }

  public can = (claim: string): boolean => {
    return this.getModel()[claim];
  }

  public static getInstance = createSingleton<CanIService>(() => new CanIService(), '__canIService');

  public static can = (claim: string): boolean =>  {
    return CanIService.getInstance().can(claim);
  }

  public static one(claim: string): Promise<boolean> {
    return this.getInstance().one(claim);
  }

  public static ensure(claims: string[]): Promise<CanIModel> {
    return this.getInstance().ensure(claims);
  }

  public static getModel(): CanIModel {
    return this.getInstance().getModel();
  }

  public static subscribeUpdatesAndNotify(listener: (model: CanIModel) => void): IDisposable {
    return this.getInstance().subscribeUpdatesAndNotify(listener);
  }

  public static unsubscribe(listener: (...args: any[]) => any): boolean {
    return this.getInstance().unsubscribe(listener);
  }
}

export default CanIService;
