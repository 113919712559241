import React from 'react';
import InternalComponentVC from '../../view-controllers/InternalComponentVC';
import useService from '../useService';
import ResourceLocatorService, { ResourcesOfDatasetsTreeService } from '../../services/ResourceLocatorService';
import createService from '../../services/createService';
import { UrlState } from '@luxms/bi-core';

interface ILoadFromResourcesProps {
  path: string;
  children?: any;
  [key: string]: any;
}

interface ILFRModel {
  error: string;
  loading: boolean;
  Component: any;
}


const LFRService = createService<ILFRModel>('LFRService', ({useService, useServiceWithCustomSubscription}, path) => {
  const url = useServiceWithCustomSubscription(UrlState, 'displayMode');
  if (url.displayMode === 'luxmsbi') return {error: null, loading: false, Component: null};

  const resourcesList = useService(ResourcesOfDatasetsTreeService, path);

  if (resourcesList.error) return {error: resourcesList.error || resourcesList.error, loading: false};
  if (resourcesList.loading) return {error: null, loading: true};

  if (!resourcesList.length) return {error: null, loading: false, Component: null};

  const schema_name: string = resourcesList[0].schema_name;
  const internalComponent = useService(InternalComponentVC, schema_name, path);

  if (internalComponent.error) return {error: internalComponent.error, loading: false, Component: null};
  if (internalComponent.loading) return {error: null, loading: true, Component: null};

  return {
    error: null,
    loading: false,
    Component: internalComponent.Component,
  };
});


const LoadFromResources = React.memo((props: ILoadFromResourcesProps) => {
  const lfr = useService(LFRService, props.path);

  if (lfr.error) { // ошибки игнорим же?
    return props.children;
  }

  if (lfr.loading) {
    return null;
  }

  return lfr.Component ? React.createElement(lfr.Component, props) : props.children;
});

export default LoadFromResources;
