import { BaseService, AppConfig, srv, repo, extractErrorMessage } from '@luxms/bi-core';
// import * as React from 'react';
// import classnames from 'classnames';
// import $ from 'jquery';
import axios from 'axios';
import * as dsHelpers from '../services/ds/ds-helpers';
import AlertsVC from './AlertsVC';
// import * as echarts from 'echarts';


async function asyncImport(module: string, _registerStyleElement) {
  if (module === 'react') return import('react');
  if (module === 'react-dom') return import('react-dom');
  if (module === 'classnames') return (await import('classnames')).default;
  if (module === 'jquery') return import('jquery');
  if (module === 'axios') return import('axios');
  if (module === 'three') return import('three');
  if (module === '@react-three/fiber') return import('@react-three/fiber');
  if (module === '@react-three/drei') return import('@react-three/drei');
  if (module === 'echarts') return import('echarts');
  if (module === 'bi-internal/utils') return {...require('../libs/bi-internal/utils'), _registerStyleElement};
  if (module === 'bi-internal/core') return import('../libs/bi-internal/core');
  if (module === 'bi-internal/face') return import('../libs/bi-internal/face');
  if (module === 'bi-internal/root') return import('../libs/bi-internal/root');
  if (module === 'bi-internal/types') return import('../libs/bi-internal/types');
  if (module === 'bi-internal/ui') return import('../libs/bi-internal/ui');
  if (module === 'bi-internal/services') return import('../libs/bi-internal/services');
  if (module === 'bi-internal/ds-helpers') return dsHelpers;
  if (module === 'bi-internal/font') return {normal: require('@fontsource/roboto/files/roboto-cyrillic-300-normal.woff')};
  if (module === 'bi-internal/_internal') return {_registerStyleElement};                             // В будущем в ресурсах в вебпаке следует брать функцию отсюда а не из utils

  debugger;
  console.error('Unknown module requested' + String(module));
  // throw new Error('Unknown module ' + module);
  return null;
}


function loadComponent(content) {
  let styleElements = [];

  const _registerStyleElement = (el) => {
    styleElements.push(el);
  };

  let _require = (module) => {
    debugger;
    // if (module === 'react') return React;
    // if (module === 'react-dom') return ReactDOM;
    // if (module === 'classnames') return classnames;
    // if (module === 'jquery') return $;
    // if (module === 'axios') return axios;
    // if (module === 'three') return require('three');
    // if (module === '@react-three/fiber') return require('@react-three/fiber');
    // if (module === '@react-three/drei') return require('@react-three/drei');
    // if (module === 'echarts') return echarts;
    // if (module === 'bi-internal/utils') return {...require('../libs/bi-internal/utils'), _registerStyleElement};
    // if (module === 'bi-internal/core') return require('../libs/bi-internal/core');
    // if (module === 'bi-internal/face') return require('../libs/bi-internal/face');
    // if (module === 'bi-internal/root') return require('../libs/bi-internal/root');
    // if (module === 'bi-internal/types') return require('../libs/bi-internal/types');
    // if (module === 'bi-internal/ui') return require('../libs/bi-internal/ui');
    // if (module === 'bi-internal/services') return require('../libs/bi-internal/services');
    // if (module === 'bi-internal/ds-helpers') return dsHelpers;
    // if (module === 'bi-internal/font') return {normal: require('@fontsource/roboto/files/roboto-cyrillic-300-normal.woff')};
    // if (module === 'bi-internal/_internal') return {_registerStyleElement};                             // В будущем в ресурсах в вебпаке следует брать функцию отсюда а не из utils
    // debugger;
    // throw new Error('Unknown module ' + module);
  };

  return new Promise(resolve => {
    let amdDefine: any = (depsNames: string[], callback) => {
      const promises = depsNames.map(dep => asyncImport(dep, _registerStyleElement));
      Promise.all(promises).then((deps) => {
        // const module = fn.apply(this, deps);
        // modules[moduleName] = module;
        const result = callback.apply(this, deps);
        resolve(result);
      }).catch((err) => {
        AlertsVC.pushDangerAlert(extractErrorMessage(err));
      });
    };
    amdDefine.amd = true;

    // const exports = {}, module = {exports};
    const exports = undefined, module = undefined;
    const _Function: any = (() => {}).constructor;
    let fn = new _Function('module', 'exports', 'require', 'define', content);
    fn(module, exports, _require, amdDefine);
  }).then((Component: any) => {
    if (Component && typeof Component === 'object') Component._styleElements = styleElements;
    return Component;
  });
}


function unloadComponent(Component: any) {
  if (Component && typeof Component === 'object') {
    Component._styleElements?.forEach(se => {
      se.parentElement?.removeChild(se);
    });
  }
}

interface IInternalComponentModel {
  error: string;
  loading: boolean;
  Component: any;
}

class InternalComponentVC extends BaseService<IInternalComponentModel> {
  private _schema_name: string;
  private _alt_id: string;
  private _resourcesService: srv.ds.ResourcesService | null = null;
  private _updated: string = '';

  public constructor(schema_name: string, alt_id: string) {
    super({
      error: '',
      loading: true,
      Component: null,
    });
    this._schema_name = schema_name;
    this._alt_id = alt_id;

    this._resourcesService = srv.ds.ResourcesService.createInstance(this._schema_name);
    this._resourcesService.subscribeUpdatesAndNotify(this._onResourcesServiceUpdated);
  }

  private _onResourcesServiceUpdated = async () => {
    const resources = this._resourcesService?.getModel();

    if (resources?.error) return this._updateModel({error: resources.error, loading: false});
    if (resources?.loading) return this._updateModel({error: '', loading: true});

    let schema_name = this._schema_name;
    const resource: repo.ds.IRawResource = resources.find(r => (r.alt_id === this._alt_id));
    if (!resource) return this._updateModel({error: `${this._alt_id} not found in ${schema_name}`, loading: false});

    const url = AppConfig.fixRequestUrl(`/srv/resources/${schema_name}/${resource.alt_id}`);

    if (this._updated === resource.updated) return;
    this._updated = resource.updated;

    try {
      const res = await axios(url);
      const Component = await loadComponent(res.data);

      if (this._model.Component) unloadComponent(this._model.Component);

      this._updateModel({error: '', loading: false, Component});

    } catch (err) {
      console.error(err);
      this._updateModel({error: extractErrorMessage(err), loading: false});
    }
  }

  protected _dispose() {
    if (this._model.Component) unloadComponent(this._model.Component);
    if (this._resourcesService) {
      this._resourcesService.unsubscribe(this._onResourcesServiceUpdated);
      this._resourcesService.release();
      this._resourcesService = null;
    }
    super._dispose();
  }
}

export default InternalComponentVC;
