import React from 'react';
import useService from '../../useService';
import L10nVC from '../../../view-controllers/L10nVC';

type L10nProps = React.PropsWithChildren<{section?: string}>;


const L10n: React.FC<L10nProps> = (props: L10nProps) => {
  const l10n = useService<L10nVC>(L10nVC);
  if (l10n.error || l10n.loading) return props.children;
  const locale = l10n.current;

  const lang = (key: string, section?: string): string => (key in locale) ? locale[key] : key;      // TODO: прикрутить поиск в секции, если есть

  const applyLocalizationToChildren = (children: any) => {                                          // Пытаемся применить локализацию к чему-то массивоподобному
    let newChildren: any;
    let isChanged: boolean = false;

    if (Array.isArray(children)) {
      newChildren = children.map(child => {
        const newChild = applyLocalization(child);                                                  // рекурсивно вызываем локализацию для всех дочерних
        if (newChild !== child) {
          isChanged = true;
          if (newChild?._store?.validated === false) {                                              // Обновляем внутреннее поле реакт ноды
            newChild._store.validated = true;                                                       //   слабоумно и отважно
          }
        }
        return newChild;
      });
    } else {                                                                                        // один (или ноль) дочерний элемент
      newChildren = applyLocalization(children);
      isChanged = newChildren !== children;                                                         // поменялся?
    }
    return isChanged ? newChildren : children;
  };

  const applyLocalization = (node) => {                                                             // пытаемся применить локализацию к чему-то элементоподобному
    if (!node) return node;
    if (typeof node === 'string') {
      const translated = lang(node.trim());
      return (translated !== node.trim()) ? translated : node;                                      // хорошо бы вернуть строке то, что мы оттримили
    }
    if (Array.isArray(node)) return applyLocalizationToChildren(node);
    if (!node.props || typeof node.props !== 'object') return node;

    let changed: boolean = false;
    let newProps: { children?: any; [key: string]: string; } = {};                                  // Сюда положим новые пропсы

    // TODO: может как-то помечать аттрибуты в React разметке?
    ['title', 'placeholder', 'description', 'warningText', 'shortTitle'].forEach(propName => {
      if (typeof node.props[propName] === 'string') {
        const originalValue = node.props[propName].trim();
        const newValue = lang(originalValue);
        if (originalValue !== newValue) {
          newProps[propName] = newValue;
          changed = true;
        }
      }
    });

    const newChildren = applyLocalization(node.props.children);
    if (node.props.children !== newChildren) {
      // newProps.children = newChildren;
      changed = true;
    }


    return changed ? React.cloneElement(node, newProps, newChildren) : node;
  };


  if (props.section === 'debugger') {
    debugger;
  }

  return applyLocalization(props.children);
};

export default L10n;
